import axios from 'axios'
import { Notify, Toast } from 'vant'


function startLoading() {
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
  })
}
function endLoading() {
  Toast.clear()
}
const service = axios.create({
  baseURL: 'https://api.web.ecapi.cn/wy_h5/',
  timeout: 500000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  config.isForm ?
    config.headers["content-type"] = "application/x-www-form-urlencoded" :
    config.headers["content-type"] = "application/json";
    config.headers["access-token"] = localStorage.getItem('token')
  console.log(config)
  if (config.baseURL.indexOf('api.test.ecapi.cn') != -1) {
    if (config.method == 'get') {
      config.params['env'] = 'dev'
    }else{
      config.data['env'] = 'dev'
    }
  }
  startLoading();
  return config
}, error => {
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    endLoading();
    return response.data
  },
  error => {
    Notify({ type: 'danger', message: '网络错误，请重试!' });
    endLoading();
    return Promise.reject(error)
  }
)

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @returns Promise
 */
export function post(url, params = {}, isForm = false) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: 'post',
      data: params,
      isForm
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @returns Promise
 */
export function get(url, params = {}, isForm = false) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: 'get',
      params: params,
      isForm
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}
